import dayjs from 'dayjs';
import Config from '@/config/config';

import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
Vue.use(VueToast);

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

const CONFIG = new Config();

const TOKEN = CONFIG.LIBRACCEE_TOKEN;

export const listAllPontosMedicao= async () => {
  var axios = require('axios');

  var config = {
    method: 'get',
    url: `${CONFIG.API_URL_LIBRACCEE}/medidor/ListarPontosMedicoes`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    }
  };

  return axios(config)
    .then(await function (response) {
      console.log(response.data)
      return response.data;
    })
    .catch(function (error) {
      console.log(error);

      return null;
    });
};

/*
export const listAllPontosMedicao1hora = async () => {
  var axios = require('axios');

  var config = {
    method: 'get',
    url: `${CONFIG.API_URL_LIBRACCEE}/medidor/ListarPontosMedicoes`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    }
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);

      return null;
    });
};*/

export const a1b1_5minutos = async (dados) => {
  console.log(dados);
  var axios = require('axios');

  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/medidas/coletar`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const a1b1todos5minutos = async (dados) => {
  var axios = require('axios');

  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/medidas/coletar_todos`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const c1_5minutos = async (dados) => {
  var axios = require('axios');

  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/medidas/inserir`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const c1todos5minutos = async (dados) => {
  var axios = require('axios');

  console.log(dados);
  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/medidas/inserir_todos`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const a1b1c1_5minutos = async (dados) => {
  console.log(dados);
  var axios = require('axios');

  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/medidas/coletar_inserir`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const a1b1c1todos_5minutos = async (dados) => {
  var axios = require('axios');

  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/medidas/coletar_inserir_todos`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const a1b1_1hora = async (dados) => {
  console.log(dados);
  var axios = require('axios');

  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/medidas/finais/coletar`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const a1b1todos1hora = async (dados) => {
  var axios = require('axios');

  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/medidas/finais/coletar_todos`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const c1_1hora = async (dados) => {
  var axios = require('axios');

  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/medidas/finais/inserir`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const c1todos1hora = async (dados) => {
  var axios = require('axios');

  console.log(dados);
  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/medidas/finais/inserir_todos`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const a1b1c1_1hora = async (dados) => {
  console.log(dados);
  var axios = require('axios');

  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/medidas/finais/coletar_inserir`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const a1b1c1todos_1hora = async (dados) => {
  var axios = require('axios');

  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/medidas/finais/coletar_inserir_todos`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const a1b1_pld1hora = async (dados) => {
  console.log(dados);
  var axios = require('axios');

  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/plds/coletar`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const c1_pld1hora = async (dados) => {
  var axios = require('axios');

  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/plds/inserir`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};

export const a1b1c1_pld1hora = async (dados) => {
  console.log(dados);
  var axios = require('axios');

  const config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/robo/plds/coletar_inserir`,
    headers: {
      'Content-Type': 'application/json',
      token: TOKEN
    },
    data: dados
  };

  return axios(config)
    .then((response) => response.data)
    .catch((error) => {
      console.error('Erro na requisição: ', error);
    });
};
