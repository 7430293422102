<template>
  <div>
    <PageHeader :title="'Robôs Coletas'" :items="breadcrumb" />

    <!-- Card para Seleção de Medidores -->
    <b-card class="mb-3 mt-3">
      <b-container fluid>
        <b-row align-v="center" class="my-3">
          <!-- Campos de Data Inicial e Final -->
          <b-col md="3">
            <b-form-group label="Data Inicial" label-for="dataInicial">
              <b-form-datepicker id="dataInicial" v-model="dataInicial" :date-format-options="{
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }"></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Data Final" label-for="dataFinal">
              <b-form-datepicker id="dataFinal" v-model="dataFinal" :date-format-options="{
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }"></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <b-card title="Medição 5 minutos" class="mb-3 mt-3">
      <b-container fluid>
        <!-- Campo de Pontos de Medição -->
        <b-row>
          <b-col md="3">
            <b-form-group label="Pontos de Medição" label-for="medidores">
              <b-form-select id="medidores" v-model="codigoMedidorSelecionado5minutos"
                :options="opcoesMedidores5minutos" />
            </b-form-group>
          </b-col>
          <b-col md="3" class="text-center mt-3">
            <b-button @click="a1b1_5minutosFunction" variant="primary" class="mt-2">Disparar A1/B1</b-button>
          </b-col>
          <b-col md="3" class="text-center mt-3">
            <b-button @click="c1_5minutosFunction" variant="primary" class="mt-2">Disparar C1</b-button>
          </b-col>
          <b-col md="3" class="text-center mt-3">
            <b-button @click="a1b1c1_5minutosFunction" variant="primary" class="text-center mt-2">Disparar A1/B1 +
              C1</b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="3" class="text-center mt-3">
            <h4>TODOS:</h4>
          </b-col>
          <b-col md="3" class="text-center mt-3">
            <b-button @click="a1b1todos5minutosFunction" variant="primary">Disparar A1/B1 Todos</b-button>
          </b-col>
          <b-col md="3" class="text-center mt-3">
            <b-button @click="c1todos5minutosFunction" variant="primary">Disparar C1 Todos</b-button>
          </b-col>
          <b-col md="3" class="text-center mt-3 mb-3">
            <b-button @click="a1b1c1todos_5minutosFunction" variant="primary">Disparar A1/B1 + C1 Todos</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <b-card title="Medição horária" class="mb-3 mt-3">
      <b-container fluid>
        <!-- Campo de Pontos de Medição -->
        <b-row>
          <b-col md="3">
            <b-form-group label="Pontos de Medição" label-for="medidores">
              <b-form-select id="medidores" v-model="codigoMedidorSelecionado1hora" :options="opcoesMedidores1hora" />
            </b-form-group>
          </b-col>
          <b-col md="3" class="text-center mt-3">
            <b-button @click="a1b1_1horaFunction" variant="primary" class="mt-2">Disparar A1/B1</b-button>
          </b-col>
          <b-col md="3" class="text-center mt-3">
            <b-button @click="c1_1horaFunction" variant="primary" class="mt-2">Disparar C1</b-button>
          </b-col>
          <b-col md="3" class="text-center mt-3">
            <b-button @click="a1b1c1_1horaFunction" variant="primary" class="text-center mt-2">Disparar A1/B1 +
              C1</b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="3" class="text-center mt-3">
            <h4>TODOS:</h4>
          </b-col>
          <b-col md="3" class="text-center mt-3">
            <b-button @click="a1b1todos1horaFunction" variant="primary">Disparar A1/B1 Todos</b-button>
          </b-col>
          <b-col md="3" class="text-center mt-3">
            <b-button @click="c1todos1horaFunction" variant="primary">Disparar C1 Todos</b-button>
          </b-col>
          <b-col md="3" class="text-center mt-3 mb-3">
            <b-button @click="a1b1c1todos_1horaFunction" variant="primary">Disparar A1/B1 + C1 Todos</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <b-card title="PLD Horário" class="mb-3 mt-3">
      <b-container fluid>
        <b-row>
          <b-col md="3" class="mt-3">
            <b-button @click="a1b1_pld1horaFunction" variant="primary" class="mt-2">Disparar A1/B1</b-button>
          </b-col>
          <b-col md="3" class="mt-3">
            <b-button @click="c1_pld1horaFunction" variant="primary" class="mt-2">Disparar C1</b-button>
          </b-col>
          <b-col md="3" class="mt-3">
            <b-button @click="a1b1c1_pld1horaFunction" variant="primary" class="text-center mt-2">Disparar A1/B1 +
              C1</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <!-- Card para Feedback -->
    <b-card v-if="feedback" class="mt-3">
      <b-container fluid>
        <b-row>
          <b-col>
            <b-alert :variant="feedback.variant" show>
              {{ feedback.mensagem }}
            </b-alert>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import PageHeader from '@/components/page-header';
import {
  listAllPontosMedicao
} from './coletaMedidas.service';
import {
  a1b1_5minutos,
  a1b1todos5minutos,
  c1_5minutos,
  c1todos5minutos,
  a1b1c1_5minutos,
  a1b1c1todos_5minutos,
  a1b1_1hora,
  a1b1todos1hora,
  c1_1hora,
  c1todos1hora,
  a1b1c1_1hora,
  a1b1c1todos_1hora,
  a1b1_pld1hora,
  c1_pld1hora,
  a1b1c1_pld1hora
} from './coletaMedidas.service';

export default {
  data() {
    return {
      codigoMedidorSelecionado5minutos: '',
      codigoMedidorSelecionado1hora: '',
      dadosMedidores5minutos: [],
      dadosMedidores1hora: [],
      medidoresSelecionados: [],
      dataInicial: null,
      dataFinal: null,
      medidoresEPerfis: [],
      opcoesMedidores5minutos: [],
      opcoesMedidores1hora: [],
      feedback: null,
      fields: [
        {
          key: 'medidor',
          label: 'Medidor',
          thClass: 'text-center'
        },
        {
          key: 'codigoPerfil',
          label: 'Código do Perfil',
          thClass: 'text-center'
        }
      ],
      breadcrumb: [
        {
          text: 'CCEE',
          href: '#'
        },
        {
          text: 'Robôs Coletas',
          active: true
        }
      ]
    };
  },
  created() {
    this.buscarDadosMedidores();
    this.definirDataPadrao();
  },
  methods: {
    formatarData(data) {
      return data.toISOString().substr(0, 10); // Retorna a data no formato 'YYYY-MM-DD'
    },
    definirDataPadrao() {
      const ontem = new Date();
      ontem.setDate(ontem.getDate() - 1);

      this.dataInicial = this.formatarData(ontem);
      this.dataFinal = this.formatarData(ontem);
    },
    a1b1_5minutosFunction() {
      a1b1_5minutos({
        codigoMedidores: [this.codigoMedidorSelecionado5minutos],
        codigoPerfilAgentes: [
          this.obterCodigoPerfilAgente(this.codigoMedidorSelecionado5minutos, this.dadosMedidores5minutos)
        ],
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    a1b1todos5minutosFunction() {
      a1b1todos5minutos({
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    c1_5minutosFunction() {
      c1_5minutos({
        codigoMedidores: [this.codigoMedidorSelecionado5minutos],
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    c1todos5minutosFunction() {
      c1todos5minutos({
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    a1b1c1_5minutosFunction() {
      a1b1c1_5minutos({
        codigoMedidores: [this.codigoMedidorSelecionado5minutos],
        codigoPerfilAgentes: [
          this.obterCodigoPerfilAgente(this.codigoMedidorSelecionado5minutos, this.dadosMedidores5minutos)
        ],
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    a1b1c1todos_5minutosFunction() {
      a1b1c1todos_5minutos({
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    a1b1_1horaFunction() {
      a1b1_1hora({
        codigoMedidores: [this.codigoMedidorSelecionado1hora],
        codigoPerfilAgentes: [
          this.obterCodigoPerfilAgente(this.codigoMedidorSelecionado1hora, this.dadosMedidores1hora)
        ],
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    a1b1todos1horaFunction() {
      a1b1todos1hora({
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    c1_1horaFunction() {
      c1_1hora({
        codigoMedidores: [this.codigoMedidorSelecionado1hora],
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    c1todos1horaFunction() {
      c1todos1hora({
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    a1b1c1_1horaFunction() {
      a1b1c1_1hora({
        codigoMedidores: [this.codigoMedidorSelecionado1hora],
        codigoPerfilAgentes: [
          this.obterCodigoPerfilAgente(this.codigoMedidorSelecionado1hora, this.dadosMedidores1hora)
        ],
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    a1b1c1todos_1horaFunction() {
      a1b1c1todos_1hora({
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    a1b1_pld1horaFunction() {
      a1b1_pld1hora({
        codigoMedidores: [this.codigoMedidorSelecionado1hora],
        codigoPerfilAgentes: [
          this.obterCodigoPerfilAgente(this.codigoMedidorSelecionado1hora, this.dadosMedidores1hora)
        ],
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    c1_pld1horaFunction() {
      c1_pld1hora({
        codigoMedidores: [this.codigoMedidorSelecionado1hora],
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    a1b1c1_pld1horaFunction() {
      a1b1c1_pld1hora({
        codigoMedidores: [this.codigoMedidorSelecionado1hora],
        codigoPerfilAgentes: [
          this.obterCodigoPerfilAgente(this.codigoMedidorSelecionado1hora, this.dadosMedidores1hora)
        ],
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal
      });
    },
    // Função auxiliar para obter o código do perfil do agente
    obterCodigoPerfilAgente(codigoMedidor, dadosMedidoresCorrespondentes) {
      const medidor = dadosMedidoresCorrespondentes.find((dado) =>
        dado.codigoMedidores.includes(codigoMedidor)
      );
      return medidor ? medidor.codigoPerfilAgente : null;
    },
    converterObjeto5Min(input) {
      // Objeto para armazenar os resultados intermediários
      const resultadoIntermediario = {};

      // Percorrer o array de entrada e organizar os dados
      input.forEach(item => {
        const codigo = item.codigoPerfilCCEE;
        const nome = item.nome5Min;

        // Se o código não estiver no resultado intermediário, inicializa um novo array
        if (!resultadoIntermediario[codigo]) {
          resultadoIntermediario[codigo] = [];
        }

        // Adiciona o nome ao array correspondente ao código
        resultadoIntermediario[codigo].push(nome);
      });

      // Converte o objeto intermediário no formato final desejado
      const resultadoFinal = Object.keys(resultadoIntermediario).map(codigo => {
        return {
          codigoPerfilAgente: codigo,
          codigoMedidores: resultadoIntermediario[codigo]
        };
      });

      return resultadoFinal;
    },
    converterObjetoFinal(input) {
      // Objeto para armazenar os resultados intermediários
      const resultadoIntermediario = {};

      // Percorrer o array de entrada e organizar os dados
      input.forEach(item => {
        const codigo = item.codigoPerfilCCEE;
        const nome = item.nomeFinal;

        // Se o código não estiver no resultado intermediário, inicializa um novo array
        if (!resultadoIntermediario[codigo]) {
          resultadoIntermediario[codigo] = [];
        }

        // Adiciona o nome ao array correspondente ao código
        resultadoIntermediario[codigo].push(nome);
      });

      // Converte o objeto intermediário no formato final desejado
      const resultadoFinal = Object.keys(resultadoIntermediario).map(codigo => {
        return {
          codigoPerfilAgente: codigo,
          codigoMedidores: resultadoIntermediario[codigo]
        };
      });

      return resultadoFinal;
    },
    buscarDadosMedidores() {
      listAllPontosMedicao()
        .then((response) => {
          if (response.success) {
            this.dadosMedidores5minutos = this.converterObjeto5Min(response.content);
            this.opcoesMedidores5minutos = this.dadosMedidores5minutos
              .flatMap((item) =>
                item.codigoMedidores.map((medidor) => ({
                  value: medidor,
                  text: medidor
                }))
              )
              .sort((a, b) => a.text.localeCompare(b.text)); // Ordenação alfabética dos medidores

            this.dadosMedidores1hora = this.converterObjetoFinal(response.content);
            this.opcoesMedidores1hora = this.dadosMedidores1hora
              .flatMap((item) =>
                item.codigoMedidores.map((medidor) => ({
                  value: medidor,
                  text: medidor
                }))
              )
              .sort((a, b) => a.text.localeCompare(b.text));
          }
        })
        .catch(() => {
          this.feedback = {
            mensagem: 'Erro ao buscar dados.',
            variant: 'danger'
          };
        });
    }
  },
  components: {
    PageHeader
  }
};
</script>
